<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">優惠券</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <button
          class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="grid.addNewRow"
        >
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增優惠券
        </button>
        <div
          v-show="!grid.isEditing && grid.isRowSelected"
          id="batch-dropdown"
          class="dropdown ml-auto mr-2 sm:ml-0"
        >
          <button
            class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center"
          >
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" />
                刪除
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <vxe-input
              type="search"
              v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字"
              clearable="true"
              @keyup="
                e => {
                  if (e.$event.keyCode === 13) grid.refresh();
                }
              "
              @clear="grid.refresh()"
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
              @click="grid.refresh"
            >
              搜尋
            </button>
          </div>
        </div>
        <div class="flex mt-2 sm:mt-0">
          <!-- <button
            class="button w-1/2 sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            @click="grid.onPrint({ mode: 'curret' })"
          >
            <FontAwesome icon="print" class="w-4 h-4 mr-2" /> 列印
          </button> -->
          <div class="dropdown w-1/2 sm:w-auto ml-2">
            <!-- <button
              class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            >
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button> -->
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <!-- <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'csv' })"
                >
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a> -->
                <!-- <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'html' })"
                >
                  <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> HTML
                </a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid ref="grid" v-bind="gridOptions" @edit="onGridEdit(row, callback)">
          <template #modal="{ row, submit, reset }">
            <vxe-form
              ref="form"
              :title-width="100"
              title-align="right"
              v-bind="formOptions"
              :data="row"
              @reset="reset"
              @submit="submit"
            >
              <template #column-member-id="{ data }">
                <SelectBox
                  v-bind="memberSelectOptions"
                  v-model="data.MemberId"
                />
              </template>
              <template #column-discount="{ data }">
                <VxeInput
                  v-if="data.DiscountType === 0"
                  v-model="data.DiscountAmount"
                  type="number"
                  placeholder="請輸入折扣金額"
                />
                <VxeInput
                  type="number"
                  v-model="data.DisplayDiscount"
                  placeholder="請輸入折數, 例如: 92折則輸入92"
                  min="1"
                  max="100"
                />
              </template>
              <template #column-quantity="{ data }">
                <VxeInput
                  v-model="data.Quantity"
                  type="number"
                  :disabled="data.Id"
                  placeholder="請輸入發行張數"
                />
              </template>
            </vxe-form>
          </template>
          <template #modal-footer>
            <vxe-button
              type="submit"
              status="primary"
              content="確認"
              @click="
                $refs.form.validate(errMap => {
                  if (errMap === undefined) $refs.form.dispatchEvent('submit');
                })
              "
            >
            </vxe-button>
            <vxe-button
              type="reset"
              content="重置"
              @click="$refs.form.dispatchEvent('reset')"
            ></vxe-button>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<style scoped>
.swiper-container {
  padding-top: 5px;
  padding-bottom: 30px;
}
</style>
<style>
textarea {
  min-height: 140px;
}

.vxe-select-option {
  max-width: 100% !important;
}
</style>

<script lang="ts">
import { defineComponent, ref, reactive } from "vue";
import CloudFun, { Operator } from "@cloudfun/core";
import { helper as $h } from "@/utils/helper";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import SelectBox, {
  SelectBoxOptions
} from "@/cloudfun/components/SelectBox.vue";

export default defineComponent({
  components: {
    Grid,
    SelectBox
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref({});

    const gridOptions: GridOptions = {
      title: "優惠券",
      canCreate: true,
      canUpdate: true,
      canDelete: true,
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      columns: [
        {
          field: "CreatedTime",
          title: "發放日期",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) => $h.formatDate(cellValue, "YYYY/MM/DD")
        },
        {
          field: "Number",
          title: "編號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Type",
          title: "類型",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) =>
            model
              ? Object.values(model.enums.CouponType).find(
                  e => e.Value === cellValue
                )?.Name
              : undefined
        },
        {
          field: "DiscountType",
          title: "折扣方式",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) =>
            model
              ? Object.values(model.enums.CouponDiscountType).find(
                  e => e.Value === cellValue
                )?.Name
              : undefined
        },
        {
          field: "StartTime",
          title: "開始時間",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) =>
            $h.formatDate(cellValue, "YYYY/MM/DD HH:mm:ss")
        },
        {
          field: "EndTime",
          title: "結束時間",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) =>
            $h.formatDate(cellValue, "YYYY/MM/DD HH:mm:ss")
        },
        {
          field: "UseTime",
          title: "狀態",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          resizable: false,
          formatter: ({ cellValue }) => (cellValue ? "已使用" : "")
        }
      ],
      promises: {
        query: model
          ? params => {
              params.condition!.and("IsReleaseNow", Operator.Equal);
              return model.dispatch("coupon/query", params);
            }
          : undefined,
        queryAll: model ? () => model.dispatch("coupon/query") : undefined,
        save: model
          ? params => model.dispatch("coupon/save", params)
          : undefined
      },
      modalConfig: { width: "80%", height: "60%", showFooter: true }
    };

    const formOptions: VxeFormProps = {
      items: [
        {
          field: "Name",
          title: "名稱",
          span: 12,
          itemRender: { name: "$input", props: { placeholder: "請輸入文字" } }
        },
        {
          field: "MemberId",
          title: "會員",
          span: 12,
          slots: { default: "column-member-id" }
        },
        {
          field: "Type",
          title: "類型",
          span: 12,
          itemRender: {
            name: "$select",
            props: { placeholder: "類型" },
            options: model
              ? Object.values(model.enums.CouponType).map(e => {
                  return { label: e.Name, value: e.Value };
                })
              : []
          }
        },
        {
          field: "DiscountType",
          title: "折扣方式",
          span: 12,
          itemRender: {
            name: "$select",
            props: { placeholder: "類型" },
            options: model
              ? Object.values(model.enums.CouponDiscountType).map(e => {
                  return { label: e.Name, value: e.Value };
                })
              : []
          }
        },
        {
          field: "StartTime",
          title: "開始時間",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "datetime", placeholder: "請輸入日期" }
          }
        },
        {
          field: "EndTime",
          title: "結束時間",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "datetime", placeholder: "請輸入日期" }
          }
        },
        {
          field: "Discount",
          title: "折扣",
          span: 12,
          slots: { default: "column-discount" }
        },
        {
          field: "Quantity",
          title: "發行張數",
          span: 12,
          slots: { default: "column-quantity" }
        }
      ],
      rules: {
        Name: [{ required: true }],
        Discount: [
          {
            required: true,
            validator: params => {
              if (
                (params.data.DiscountType === 1 &&
                  !params.data.DisplayDiscount) ||
                (params.data.DiscountType === 0 && !params.data.DiscountAmount)
              ) {
                return new Error();
              }
            }
          }
        ],
        Quantity: [
          {
            required: false,
            validator: params => {
              if (!params.data.Id && !params.data.Quantity) {
                return new Error();
              }
            }
          }
        ]
      }
    };

    const memberSelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: "value",
      placeholder: "選擇會員",
      textField: "label",
      valueField: "value",
      columns: [
        {
          field: "label",
          title: "姓名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      promises: {
        find: value =>
          new Promise(resolve => {
            model!
              .dispatch("member/find", value)
              .then(res => resolve({ label: res.Person.Name, value: res.Id }));
          }),
        query: params => model!.dispatch("member/options", params) // eslint-disable-line
      }
    };

    return {
      grid,
      gridOptions,
      formOptions,
      memberSelectOptions
    };
  },
  methods: {
    onGridEdit(row: any, callback: any) {
      if (row.Id) {
        row.DisplayDiscount = 100 - row.Discount * 100;
      } else {
        row.Type = 0;
        row.DiscountType = 0;
      }
      callback();
    },
    onFormSubmit(row: any, callback: any) {
      if (row.DiscountType === 1) {
        row.Discount = 0;
      } else {
        row.DiscountAmount = 0;
        row.Discount = (100 - row.DisplayDiscount) / 100;
      }
      callback();
    }
  }
});
</script>
